// src/pages/Home/Home.tsx

import React from "react";
import { Typography, Button } from "@mui/material";
import MainLayout from "components/templates/MainLayout/MainLayout";
import styles from "./Home.module.css";
import { Link } from "react-router-dom";

/**
 * Homeページコンポーネント
 */
const Home: React.FC = () => {
  return (
    <MainLayout title="Home">
      <div className={styles.homePage}>
        <Typography variant="h3" gutterBottom className={styles.title}>
          ようこそ、当社のホームページへ！
        </Typography>
        <Typography variant="body1" paragraph>
          当社はテクノロジーを駆使して、あなたの日常をより良くする製品やサービスを提供しています。
        </Typography>
        <Typography variant="body1" paragraph>
          最新のプロジェクトや製品、ニュースをこちらのページで紹介しています。
        </Typography>
        <Button variant="contained" color="primary" className={styles.button}>
          <Link to="/list" className={styles.link}>
            製品一覧を見る
          </Link>
        </Button>
      </div>
    </MainLayout>
  );
};

export default Home;
