import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6", // プライマリカラー (青系)
      light: "#778bff",
      dark: "#0044b2",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#19857b", // セカンダリカラー (緑系)
      light: "#4fb3bf",
      dark: "#005f56",
      contrastText: "#000000",
    },
    error: {
      main: "#f44336", // エラーカラー (赤系)
      light: "#e57373",
      dark: "#d32f2f",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#ffa726", // 警告カラー (オレンジ系)
      light: "#ffb74d",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#2196f3", // 情報カラー (明るい青系)
      light: "#64b5f6",
      dark: "#1976d2",
      contrastText: "#ffffff",
    },
    success: {
      main: "#4caf50", // 成功カラー (緑系)
      light: "#81c784",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    // その他のカラーカスタマイゼーションも可能
  },
  // 他のテーマ設定（タイポグラフィ、スペーシングなど）もここに追加
});

export default theme;
