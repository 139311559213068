import React from "react";
import { Typography, Button } from "@mui/material";
import MainLayout from "components/templates/MainLayout/MainLayout";
import styles from "./About.module.css";

/**
 * Aboutページコンポーネント
 */
const About: React.FC = () => {
  return (
    <MainLayout title="About Us">
      <div className={styles.aboutPage}>
        <Typography variant="h4" gutterBottom className={styles.title}>
          当社について
        </Typography>
        <Typography variant="body1" paragraph>
          当社は1990年に設立され、技術の進歩とともに様々なサービスを提供してきました。
          我々のミッションは、最高品質の製品とサービスをお客様に提供することです。
        </Typography>
        <Typography variant="body1" paragraph>
          これまでの30年間で、多くの業界のリーダーたちと提携し、
          革新的なソリューションを生み出してきました。
        </Typography>
        <Button variant="contained" color="primary" className={styles.button}>
          さらに詳しく
        </Button>
      </div>
    </MainLayout>
  );
};

export default About;
