import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "pages/Home/Home";
import About from "pages/About/About";
import SomePage from "pages/SomePage/SomePage";
import CardPagination from "pages/CardPaging/CardPaging";
import { ThemeProvider } from "@mui/material";
import theme from "theme/theme";

const AppRoutes: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/somepage" element={<SomePage />} />
            <Route path="/list" element={<CardPagination />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default AppRoutes;
