import React from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
  Link,
  Box,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface BreadcrumbsProps {
  path: { label: string; href?: string }[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ path }) => {
  return (
    <Box sx={{ p: 2, bgcolor: "background.paper" }}>
      <MUIBreadcrumbs
        separator={<NavigateNextIcon fontSize="large" />}
        aria-label="breadcrumb"
      >
        {path.map((item, index) => {
          if (index === path.length - 1 || !item.href) {
            return (
              <Typography key={index} color="textPrimary">
                {item.label}
              </Typography>
            );
          }
          return (
            <Link
              key={index}
              color="inherit"
              href={item.href}
              sx={{
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {item.label}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
