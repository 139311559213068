import React, { useEffect } from "react";
import { AppBar, Toolbar, Button, Container, Link } from "@mui/material";
import styles from "./Header.module.css";
import { ReactComponent as KfWordLogo } from "assets/images/kf_wordlogo_opt.svg";
import { ReactComponent as KfOnePoint } from "assets/images/kf_onepoint_opt.svg";
import { Link as RouterLink } from "react-router-dom"; // react-router-domのLinkをインポート

/**
 * Headerコンポーネントのプロパティの型定義
 */
interface HeaderProps {
  title: string; // サイトのタイトル
  navLinks?: { label: string; path: string }[]; // ヘッダー内のナビゲーションリンク
}

/**
 * MUIを使用したヘッダーコンポーネント
 * @param props HeaderProps
 */
const Header: React.FC<HeaderProps> = ({ title, navLinks = [] }) => {
  useEffect(() => {
    const setRandomGradient = (elements: HTMLElement[]) => {
      const colors = [
        "#003366",
        "#66ccff",
        "#ff6600",
        "#ffcc00",
        "#66ff66",
        "#cc0066",
      ];
      const getRandomColor = () =>
        colors[Math.floor(Math.random() * colors.length)];

      const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;
      const svgDefs = `
        <defs>
          <linearGradient id="${gradientId}" x1="0%" y1="100%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color:${getRandomColor()};stop-opacity:1" />
            <stop offset="100%" style="stop-color:${getRandomColor()};stop-opacity:1" />
          </linearGradient>
        </defs>
      `;

      elements.forEach((element) => {
        if (element) {
          element.innerHTML = svgDefs + element.innerHTML;
          const paths = element.querySelectorAll("path");
          paths.forEach((path) => {
            (path as SVGPathElement).setAttribute(
              "fill",
              `url(#${gradientId})`
            );
          });
        }
      });
    };
    const kfOnePointElement = document.querySelector(
      `.${styles.kfOnePoint}`
    ) as HTMLElement;
    const kfWordLogoElement = document.querySelector(
      `.${styles.kfWordLogo}`
    ) as HTMLElement;

    setRandomGradient([kfOnePointElement, kfWordLogoElement]);
  }, []);

  return (
    <AppBar position="static" elevation={0} className={styles.header}>
      <Container>
        <Toolbar>
          <Link component={RouterLink} to="/" className={styles.logoContainer}>
            <KfOnePoint className={styles.kfOnePoint} />
            <KfWordLogo className={styles.kfWordLogo} />
          </Link>

          <div className={styles.navLinks}>
            {navLinks.map((link, index) => (
              <Button key={index} color="inherit" href={link.path}>
                {link.label}
              </Button>
            ))}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
