import React from "react";
import "./App.css";
import AppRoutes from "routes/AppRoutes";
import { Helmet } from "react-helmet";
import favicon from "assets/images/kf_onepoint_opt.svg";

const App: React.FC = () => {
  return (
    <div>
      <Helmet>
        {/* ページのタイトル */}
        <title>KF - イベントリスト</title>

        {/* メタディスクリプション */}
        <meta
          name="description"
          content="KFの最新イベントリストです。参加者募集中！"
        />

        {/* キーワード */}
        <meta
          name="keywords"
          content="イベント, KF, 参加者募集, イベントリスト"
        />

        {/* ファビコン */}
        <link rel="icon" type="image/svg+xml" href={favicon} />
        <link rel="alternate icon" href={favicon.replace(".svg", ".png")} />

        {/* Apple Touchアイコン */}
        <link rel="apple-touch-icon" href={favicon} />

        {/* Open Graph (OG) タグ */}
        <meta property="og:title" content="KF - イベントリスト" />
        <meta
          property="og:description"
          content="KFの最新イベントリストです。参加者募集中！"
        />
        <meta property="og:image" content={favicon} />
        <meta property="og:url" content="https://konfre-front.pages.dev" />
        <meta property="og:type" content="website" />

        {/* Twitterカード */}
        {/*
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="KF - イベントリスト" />
        <meta
          name="twitter:description"
          content="KFの最新イベントリストです。参加者募集中！"
        />
        <meta name="twitter:image" content={favicon} />
        <meta name="twitter:site" content="@KF_Official" /> */}
      </Helmet>
      <AppRoutes />
    </div>
  );
};

export default App;
