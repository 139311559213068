import React from "react";
import DataTable from "components/organisms/DataTable/DataTable";
import MainLayout from "components/templates/MainLayout/MainLayout";

const SomePage: React.FC = () => {
  // デモのためのサンプルデータ
  const data = [
    { id: 1, name: "Alice", age: 25 },
    { id: 2, name: "Bob", age: 30 },
    { id: 3, name: "Charlie", age: 22 },
    { id: 4, name: "David", age: 29 },
    { id: 5, name: "Eve", age: 33 },
    { id: 6, name: "Frank", age: 27 },
    { id: 7, name: "Grace", age: 24 },
    { id: 8, name: "Hannah", age: 26 },
    { id: 9, name: "Ivan", age: 31 },
    { id: 10, name: "Jenny", age: 28 },
    { id: 11, name: "Kevin", age: 32 },
    { id: 12, name: "Linda", age: 21 },
    { id: 13, name: "Mike", age: 34 },
    { id: 14, name: "Nina", age: 25 },
    { id: 15, name: "Oscar", age: 23 },
    { id: 16, name: "Penny", age: 26 },
    { id: 17, name: "Quinn", age: 30 },
    { id: 18, name: "Rita", age: 28 },
    { id: 19, name: "Steve", age: 29 },
    { id: 20, name: "Tracy", age: 31 },
    { id: 21, name: "Ulysses", age: 33 },
    { id: 22, name: "Vera", age: 27 },
    { id: 23, name: "Will", age: 25 },
    { id: 24, name: "Xena", age: 28 },
    { id: 25, name: "Yoshi", age: 24 },
    { id: 26, name: "Zack", age: 29 },
    { id: 27, name: "Aria", age: 32 },
    { id: 28, name: "Brian", age: 30 },
    { id: 29, name: "Celia", age: 21 },
    { id: 30, name: "Derek", age: 34 },
    { id: 31, name: "Elma", age: 27 },
    { id: 32, name: "Felix", age: 23 },
    { id: 33, name: "Gloria", age: 28 },
    { id: 34, name: "Harry", age: 25 },
    { id: 35, name: "Iris", age: 29 },
    { id: 36, name: "Jack", age: 24 },
    { id: 37, name: "Kara", age: 33 },
    { id: 38, name: "Leo", age: 31 },
    { id: 39, name: "Mona", age: 26 },
    { id: 40, name: "Nate", age: 32 },
    { id: 41, name: "Olga", age: 30 },
    { id: 42, name: "Pete", age: 28 },
    { id: 43, name: "Queen", age: 34 },
    { id: 44, name: "Rob", age: 31 },
    { id: 45, name: "Sara", age: 33 },
    { id: 46, name: "Tom", age: 29 },
    { id: 47, name: "Ursa", age: 25 },
    { id: 48, name: "Vic", age: 32 },
    { id: 49, name: "Wendy", age: 23 },
    { id: 50, name: "Xander", age: 24 },
  ];

  return (
    <MainLayout title="Home">
      <div>
        <DataTable data={data} />
      </div>
    </MainLayout>
  );
};

export default SomePage;
