import React, { useState, useEffect, useCallback } from "react";
import { Typography, Pagination, Box } from "@mui/material";
import axios from "axios";
import styles from "./CardPaging.module.css";
import MainLayout from "components/templates/MainLayout/MainLayout";
import EventCard from "components/organisms/EventCard/EventCard";
import { useNavigate, useLocation } from "react-router-dom";

interface EventData {
  id: number;
  event_title: string;
  event_start_date: string;
  event_end_date: string;
  event_location: string;
  event_url: string;
  m_target_age_younger: number;
  m_target_age_older: number;
  m_target_status: string;
  f_target_age_younger: number;
  f_target_age_older: number;
  f_target_status: string;
  circle_types_master: {
    circle_name: string;
  };
}

const CardPagination: React.FC = () => {
  // const [page, setPage] = useState(1);
  const [events, setEvents] = useState<EventData[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const history = useNavigate();
  const location = useLocation();

  const getPageFromQuery = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("page") || "1", 10);
  };

  const [page, setPage] = useState(getPageFromQuery());

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history(`?page=${value}`);
    setPage(value);
  };

  const fetchEvents = useCallback(async (page: number) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.konfre.com/api/events/get?page=${page}`
      );
      const newEvents = response.data.data;
      const totalPages = Math.ceil(
        response.data.total / response.data.per_page
      );
      setEvents(newEvents);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchEvents(page);
  }, [page, fetchEvents]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageFromQuery = parseInt(params.get("page") || "1", 10);
    if (pageFromQuery !== page) {
      setPage(pageFromQuery);
    }
  }, [location.search, page]);

  return (
    <MainLayout title="イベント一覧">
      <Box>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          events.map((item) => <EventCard key={item.id} item={item} />)
        )}

        <div className={styles.paginationCenter}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </div>
      </Box>
    </MainLayout>
  );
};

export default CardPagination;
