import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Link as MuiLink,
  ButtonBase,
  Chip,
  Grid,
} from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import image from "assets/images/images.jpg";
import { ReactComponent as MaleIcon } from "assets/images/icon/male.svg";
import { ReactComponent as FemaleIcon } from "assets/images/icon/female.svg";
import { ReactComponent as CalenderIcon } from "assets/images/icon/calender.svg";
import { ReactComponent as Pin } from "assets/images/icon/pin.svg";
import styles from "./EventCard.module.css";
import styled from "@emotion/styled";

interface EventCardProps {
  item: {
    id: number;
    event_title: string;
    event_start_date: string;
    event_end_date: string;
    event_location: string;
    event_url: string;
    m_target_age_younger: number;
    m_target_age_older: number;
    m_target_status: string;
    f_target_age_younger: number;
    f_target_age_older: number;
    f_target_status: string;
    circle_types_master: {
      circle_name: string;
    };
  };
}

const CustomButtonBase = muiStyled(ButtonBase)(({ theme }) => ({
  "& .MuiTouchRipple-root": {
    color: "rgba(100, 100, 100, 0.48)", // リップルエフェクトの色を変更
  },
}));

const Label = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "gray",
  color: "white",
  padding: "4px 8px",
  borderRadius: "10px 10px 0 0",
  fontSize: "0.9rem",
}));

const BottomLabel = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "white",
  padding: "2px 8px",
  fontSize: "0.9rem",
  borderTop: "1px dashed #CCC",
  borderBottom: "1px dashed #CCC",
  fontWeight: "bold",
}));

const ImageContainer = styled(Box)({
  position: "relative",
  width: "100%",
  height: "240px",
  overflow: "hidden",
  borderRadius: "10px 10px 0 0",
  "& img": {
    width: "100%",
    height: "auto",
  },
});

const EventCard: React.FC<EventCardProps> = ({ item }) => {
  return (
    <MuiLink
      href={item.event_url}
      target="_blank"
      rel="noopener noreferrer"
      key={item.id}
      underline="none"
    >
      <CustomButtonBase
        sx={{
          width: "100%",
          mb: 1.4,
          border: "2px solid #gray",
          borderRadius: "10px",
          boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card key={item.id} sx={{ width: "100%", borderRadius: "10px" }}>
          <ImageContainer>
            <Label>{item.circle_types_master.circle_name}</Label>
            <img src={image} alt="Event" />
            <BottomLabel>
              <Box
                sx={{
                  whidth: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CalenderIcon className={styles.calenderIcon} />
                <Typography
                  sx={{ fontWeight: "bold", fontSize: 18, color: "gray" }}
                >
                  {new Date(item.event_start_date).toLocaleDateString("ja-JP", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    weekday: "short",
                  })}
                </Typography>
              </Box>
            </BottomLabel>
          </ImageContainer>
          <CardContent sx={{ p: "0 !important" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {item.event_title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Chip
                avatar={<Pin className={styles.pin} />}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: "gray",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.event_location}
                    </Typography>
                  </Box>
                }
                sx={{ margin: "8px" }}
              />
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "gray",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {new Date(item.event_start_date).toLocaleTimeString("ja-JP", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                〜
                {new Date(item.event_end_date).toLocaleTimeString("ja-JP", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  border: "1px dashed #CCC",
                  padding: 1,
                  borderRadius: 2,
                  width: { xs: "92%", md: "25%" },
                  mr: { xs: 0, md: 1 },
                }}
              >
                <Grid container>
                  <Grid item xs={4}>
                    <MaleIcon className={styles.maleIcon} />
                  </Grid>
                  <Grid item xs={8}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          color: "gray",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.m_target_status}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          color: "gray",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.m_target_age_younger}〜{item.m_target_age_older}歳
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  border: "1px dashed #CCC",
                  padding: 1,
                  borderRadius: 2,
                  width: { xs: "92%", md: "25%" },
                  ml: { xs: 0, md: 1 },
                  mt: { xs: 1, md: 0 },
                }}
              >
                <Grid container>
                  <Grid item xs={4}>
                    <FemaleIcon className={styles.femaleIcon} />
                  </Grid>
                  <Grid item xs={8}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          color: "gray",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.f_target_status}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          color: "gray",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.f_target_age_younger}〜{item.f_target_age_older}歳
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </CustomButtonBase>
    </MuiLink>
  );
};

export default EventCard;
