import React from "react";
import { Container, Typography, Link, Box, Divider } from "@mui/material";
import styles from "./Footer.module.css";

/**
 * Footerコンポーネントのプロパティの型定義
 */
interface FooterProps {
  companyName?: string; // 会社の名前
  year?: string | number; // 著作権の年
  links?: { label: string; href: string }[]; // フッターリンクの配列
}

/**
 * MUIを使用したフッターコンポーネント
 * @param props FooterProps
 */
const Footer: React.FC<FooterProps> = ({
  companyName = "Konfre.com",
  year = new Date().getFullYear(),
  links = [],
}) => {
  return (
    <div>
      <Divider />
      <Box my={4}>
        <Typography variant="h6" gutterBottom>
          Konfreです。
        </Typography>
        <Typography variant="body1" gutterBottom>
          実際の参加者から、評価基準にそって口コミを投稿していただき、
          それらの情報をみなさまに提供しています。
          <br />
          また、そのサークルのイベントや出会いに関する様々な情報も掲載しています。
          <br />
          気になったイベントが見つかった場合は、ブックマークに登録したり、そのイベントページにジャンプすることができます。
          <br />
          これらの機能や情報を活用して、自分にピッタリのイベントやサークルを見逃さないようにしましょう！
        </Typography>
      </Box>
      <Box component="footer" className={styles.footer}>
        <Container>
          <Box className={styles.socialLinks}>
            {/* 例えばソーシャルメディアのアイコンリンク */}
            <a href="https://facebook.com" className={styles.socialLink}>
              Facebook
            </a>
            <a href="https://twitter.com" className={styles.socialLink}>
              Twitter
            </a>
            <a href="https://instagram.com" className={styles.socialLink}>
              Instagram
            </a>
            {/* 他のソーシャルメディアのリンクもここに追加 */}
          </Box>
        </Container>
        <Container>
          <Typography variant="body2" align="center">
            &copy; {year} {companyName}. All rights reserved.
          </Typography>

          {links.length > 0 && (
            <Box className={styles.footerLinks}>
              {links.map((link, index) => (
                <Link
                  key={index}
                  href={link.href}
                  className={styles.footerLink}
                >
                  {link.label}
                </Link>
              ))}
            </Box>
          )}
        </Container>
      </Box>
    </div>
  );
};

export default Footer;
