import React from "react";
import { Container } from "@mui/material";
import Header from "components/organisms/Header/Header";
import Footer from "components/organisms/Footer/Footer";
import styles from "./MainLayout.module.css";
import Breadcrumbs from "components/organisms/Breadcrumbs/Breadcrumbs";

/**
 * MainLayoutコンポーネントのプロパティの型定義
 */
interface MainLayoutProps {
  children: React.ReactNode; // 子コンポーネント
  title?: string; // サイトのタイトル
  navLinks?: { label: string; path: string }[]; // ヘッダー内のナビゲーションリンク
}

/**
 * MUIを使用したメインレイアウトコンポーネント
 * @param props MainLayoutProps
 */
const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  title = "My Website",
  navLinks = [],
}) => {
  const breadcrumbPath = [
    { label: "Home", href: "/" },
    { label: "Home", href: "/" },
    { label: "Home", href: "/" },
    { label: "Home", href: "/" },
    { label: "Home", href: "/" },
    { label: "About" }, // 最後のアイテムはリンクを持たないことが多い
  ];
  return (
    <div className={styles.layout}>
      <Header title={title} navLinks={navLinks} />
      <Breadcrumbs path={breadcrumbPath} />
      <Container className={styles.content} sx={{ p: 1 }}>
        {children}
      </Container>
      <Footer />
    </div>
  );
};

export default MainLayout;
